import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { get, post } from "../../api/api";
import {
  ADD_DEMOGRAPHICS_EXEMPTION_CASE,
  BASE_URL,
  BASE_URL_FULCRUMONE_NOTIFICATIONS_SERVICE,
  GET_HEARING_DATA_BY_HEARING_CODE,
} from "../../api/baseURL";
import { useParams } from "react-router-dom";
import namlogo from "../../assets/img/namlogo.png";
const CaseStatus = () => {
  const { id, status, empId } = useParams();
  console.log(status);
  const [submitHandle, setSubmitHandle] = useState(false);
  const [isSuccessMessage, setIsSuccessMessage] = useState(true);
  const [successMessage, setSuccessMessage] = useState(true);
  const [emailNotificationMessage, setEmailNotificationMessage] = useState("Loading...");
  const [waiting, setWaiting] = useState(false);
  useEffect(() => {
    demographicExemption();
  }, []);
  const getHearingDataByCode = async () => {
    setWaiting(true);
    const res = await getHearingData(id);
    if (res.status === 200) {
      const payload = {
        caseReference: res.data[0].caseReference,
        hearingOfficerName: res.data[0].hearingOfficerName,
        hearingOfficerEmail: res.data[0].hearingOfficerEmail,
      };
      sendEmailNotificationForContinuedHearing(payload);
    }
  };
  const sendEmailNotificationForContinuedHearing = async (payload) => {
    setWaiting(true);
    let subject = ` Exemption Request ${
      status == 1 ? "Approved" : "Denied"
    } - [Case ID: ${payload.caseReference}]`;

    const emailBody = {
      listEmailReceivers: [
        {
          receiverName: payload.hearingOfficerName,
          receiverEmail: payload.hearingOfficerEmail,
          emailBodyVariables: {
            additionalProp1: "",
          },
        },
      ],
      emailSubject: subject,
      emailTemplate: `<!DOCTYPE html><html lang="en"><head><title>Email Template</title></head><body style="font-family: Arial, sans-serif; line-height: 1.6; color: #333;"><p style="font-weight: bold;">Dear ${
        payload.hearingOfficerName
      },</p>
        <p>Your request for an exemption from completing the demographics form for case <strong> Case ID: ${
          payload.caseReference
        }</strong> has been reviewed and ${
        status == 1 ? "approved" : "denied"
      } by the NAM employee.</p>
      <p>
      ${
        status == 1 ? "No further action is required on your part regarding the demographics form and you may mark the task as complete." : "Please submit the demographics form at your earliest convenience."
      }
      </p>
       <p>Thank you,</p><p>NAM Neutral Portal Customer Service</p><p>+1 (800) 358-2550</p><img src="${BASE_URL}${namlogo}" width="310px"></img><p><strong>CONFIDENTIALITY NOTICE:</strong> The information contained in this message, and in any accompanying documents, may be proprietary, confidential, and/or privileged information which belongs to NAM (National Arbitration and Mediation). This information is intended only for the use of the individual or entity to whom it is addressed. If you are not the intended recipient of this email, or a person who is responsible for delivering emails to such intended recipient, you are hereby notified that any disclosure, copying, distribution, or the taking of any action in reliance on this information, is strictly prohibited. If you have received this message in error, please immediately notify the sender by email and delete the original message.<p/></div></body></html>`,
      filename: "",
      cc: "",
      bcc: "",
    };

    const res = await sendMailToAllParticipants(emailBody);
    if (res.status === 200) {
      status==1 ? setEmailNotificationMessage("The approval email notification has been sent to the Neutral.") : setEmailNotificationMessage("The denial email notification has been sent to the Neutral.");

    }
  };
  const cardStyle = {
    padding: "20px 30px",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    backgroundColor: "#fff",
    textAlign: "center",
    maxWidth: "400px",
  };

  const messageStyle = {
    fontSize: "18px",
    color: "#333",
    margin: 0,
  };

  const containerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    backgroundColor: "#f0f0f0",
    margin: 0,
  };

  const sendMailToAllParticipants = (payload) => {
    setWaiting(true);
    return new Promise(async (resolve, reject) => {
      try {
        const res = await post(
          `${BASE_URL_FULCRUMONE_NOTIFICATIONS_SERVICE}/api/customemailwithbodyvariables`,
          payload
        );
        setSubmitHandle(false);
        resolve(res);
      } catch (err) {
        setSubmitHandle(false);
        reject(err);
      }
    });
  };
  const getHearingData = (payload) => {
    setWaiting(true);
    return new Promise(async (resolve, reject) => {
      try {
        const res = await get(
          `${GET_HEARING_DATA_BY_HEARING_CODE}?hearingCode=${payload}`,
          {}
        );
        // setSubmitHandle(false);
        resolve(res);
      } catch (err) {
        setSubmitHandle(false);
        reject(err);
      }
    });
  };
  const demographicExemption = async () => {
    const payload = {
      hearingCode: id,
      status: status == 1 ? true : false,
      empId: empId,
    };
    const res = await demographicExemptionCase(payload);
    if (res.status === 200) {
      setIsSuccessMessage(res.data.isSuccess);
      setSuccessMessage(res.data.message);
      setWaiting(true);
      if (res.data.isSuccess) {
        getHearingDataByCode();
      }
      else{
        setSubmitHandle(false);
      }
    }
  };
  const demographicExemptionCase = (payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await post(
          `${ADD_DEMOGRAPHICS_EXEMPTION_CASE}?HearingCode=${payload.hearingCode}&IsApproved=${payload.status}&EmpId=${payload.empId}`,
          {}
        );
        // setSubmitHandle(false);
        resolve(res);
      } catch (err) {
        setSubmitHandle(false);
        reject(err);
      }
    });
  };
  return (
    <>
      {submitHandle && (
        <div className="transparent--loader">
          <div className="loading">
            <CircularProgress />
          </div>
        </div>
      )}
      <div style={containerStyle}>
        <div style={cardStyle}>
          <h1 style={messageStyle}>
            {waiting && (!successMessage || !emailNotificationMessage)
            ? "Loading..."
            : waiting &&
              (isSuccessMessage === false
                ? successMessage
                :emailNotificationMessage)}
            {!waiting && "Loading..."}
          </h1>
        </div>
      </div>
    </>
  );
};

export default CaseStatus;
