import axios from 'axios';
import {
  COPY_FILES_BLOB,
  COPY_FILES_FROM_CMS1,
  DELETE_FILE_BLOB,
  GET_FILE_DOWNLOADURL_BLOB,
  UPLOAD_MULTIPLE_FILES,
} from "../api/baseURL";

// Upload multiple files
export const uploadMultipleFilesToBlob = async (
  blobContainerId,
  blobPath,
  index,
  filesToUpload,
  newfilename
) => {
  try {
    let formData = new FormData();
    formData.append("containerId", blobContainerId);
    formData.append("path", blobPath);
    formData.append("newfilename", newfilename || ""); // Adding an empty string fallback for filename
    filesToUpload.forEach((file) => {
      formData.append("files", file);
    });

    const res = await axios.post(UPLOAD_MULTIPLE_FILES, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (res.status === 200) {
      return { fileUploaded: true, index: index, file: filesToUpload };
    }

    return { fileUploaded: false, index: index, file: filesToUpload };
  } catch (error) {
    console.error("Error uploading files to blob:", error);
    return { fileUploaded: false, index: index, file: filesToUpload };
  }
};

// Delete multiple files
export const deleteMultipleFilesFromBlob = async (
  blobContainerId,
  blobPath,
  fileNames
) => {
  try {
    let requestBody = {
      containerId: blobContainerId,
      path: blobPath,
      fileNames: fileNames,
    };

    const res = await axios.post(DELETE_FILE_BLOB, requestBody);

    if (res.status === 200 || res.status === 207) {
      return res.data;
    }

    return null;
  } catch (error) {
    console.error("Error deleting files from blob:", error);
    return null;
  }
};

// Get file download URL
export const getFileDownloadUrl = async (blobContainerId, blobPath, fileName) => {
  try {
    const queryParams = `?containerId=${blobContainerId}&path=${blobPath}&fileName=${fileName}`;

    const res = await axios.get(GET_FILE_DOWNLOADURL_BLOB + queryParams);

    if (res.status === 200) {
      return {
        fileDownloadUrlCreated: true,
        fileDownloadUrl: res.data,
      };
    }

    return {
      fileDownloadUrlCreated: false,
      fileDownloadUrl: null,
    };
  } catch (error) {
    console.error("Error getting file download URL:", error);
    return {
      fileDownloadUrlCreated: false,
      fileDownloadUrl: null,
    };
  }
};

// Copy files
export const copyFilesFromBlob = async (
  sourceBlobContainerId,
  sourcePath,
  destinationBlobContainerId,
  destinationPath
) => {
  try {
    let requestBody = {
      sourceContainerId: sourceBlobContainerId,
      sourcePath: sourcePath,
      destinationContainerId: destinationBlobContainerId,
      destinationPath: destinationPath,
    };

    const res = await axios.post(COPY_FILES_BLOB, requestBody);

    if (res?.status === 200 || res?.status === 207) {
      return res.data;
    }

    return null;
  } catch (error) {
    console.error("Error copying files from blob:", error);
    return null;
  }
};

// Copy files from CMS1
export const copyFilesFromCMS1 = async (
  sourcePath,
  destinationBlobContainerId,
  destinationPath
) => {
  try {
    let requestBody = {
      sourcePath: sourcePath,
      destinationContainerId: destinationBlobContainerId,
      destinationPath: destinationPath,
    };

    const res = await axios.post(COPY_FILES_FROM_CMS1, requestBody);

    if (res?.status === 200 || res?.status === 207) {
      return res.data;
    }

    return null;
  } catch (error) {
    console.error("Error copying files from CMS1:", error);
    return null;
  }
};