import React, { useEffect, useState } from "react";
//// import EditIcon from "@mui/icons-material/Edit";
import {
  CircularProgress,
  Grid,
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
  TextField,
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import AddIcon from "@mui/icons-material/Add";
import { get, post, put } from "../../api/api";
import "./categories.css";
import { decryptItem } from "../../shared/Encrypt";
import { BASE_URL_PRE_DEV } from "../../api/baseURL";
import ModalPopup from "f1-modal-react";
function DemographicItem() {
  const [specialtiesList, setSpecialtiesList] = useState([]);

  const loggedInUserId = `${decryptItem("userid")}`;

  const [openModel, setOpenModel] = useState(false);
  const [externalRedirect, setExternalRedirect] = useState(false);
  const [catIsEdit, setCatIsEdit] = useState(-1);
  const [inputCategory, setInputCategory] = useState("");
  const [saveCategory, setSaveCategory] = useState(false);
  const [changeItem, setChangeItem] = useState(false);
  const [categoryTitle, setCategoryTitle] = useState("");
  const [specialtyCategory, setSpecialtyCategory] = useState([]);
  const [handleRefresh, setHandleRefresh] = useState(0);
  const [defaultSelected, setDefaultSelected] = useState(0);
  const [mainCategory, setMainCategory] = useState("");
  const [addNew, handleAddNew] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [categoryError, setCategoryError] = useState(false);
  const [categoryMessage, setCategoryMessage] = useState("");
  const [mainCatId, setMainCatId] = useState(0);
  const [speciaLtyError, setSpeciaLtyError] = useState(false);
  const [specialtyMessage, setSpecialtyMessage] = useState("");
  const [disableAdd, setDisableAdd] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showText, setShowText] = useState("");
  const [okText, setOkText] = useState("");
  const [submitHandle, setSubmitHandle] = useState(false);
  useEffect(() => {
    getallmasterdata();
  }, [handleRefresh]);
  const getallmasterdata = async () => {
    const res = await getAllMasterData("DemographicForm");

    if (res.status === 200) {
      setSpecialtyCategory(res.data);

      // setSpecialtiesList(data)
      setDefaultSelected(res.data[0]?.id);
      setCategoryTitle(res.data[0]?.categoryName);
      setSubmitHandle(true);
      const response = await handleChangeSpecialtyAction(res.data[0]?.id);
      if (response.status === 200) {
        setSubmitHandle(false);

        if (response) {
          const hasActiveRecord = response.data.some((item) => item.isActive);
          if (hasActiveRecord) {
            setChangeItem(true);
          } else {
            setChangeItem(false);
          }
          setSpecialtiesList(response.data);
        }
      }
    }
  };

  const getAllMasterData = (payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await get(
          `${BASE_URL_PRE_DEV}/api/getadmincategoriesbymodalname?modalName=${payload}`
        );
        setSubmitHandle(false);
        resolve(res);
      } catch (err) {
        setSubmitHandle(false);
        reject(err);
      }
    });
  };
  const handleSelectAll = (reqParam) => {
    setSelectAll(reqParam);
    let data = [];
    [...specialtiesList].forEach((element) => {
      element.isActive = reqParam;
      data.push(element);
    });
    setSpecialtiesList(data);
  };
  const handleSingle = (id, status) => {
    let parentIndex = -1;
    let data = [];
    [...specialtiesList].forEach((element, index) => {
      if (element.id === id) {
        element.isActive = status;
      }
      data.push(element);
    });
    setSpecialtiesList(data);
    setChangeItem(true);
  };
  const checkBlankEdit = () => {
    let status = false;
    let data = [...specialtiesList];
    data.forEach((element, index) => {
      if (element.optionName.trim() === "") {
        status = true;
      }
    });
    return status;
  };
  const handleSave = async (type) => {
    if (specialtiesList.length === 0) {
      return;
    }
    let data = [...specialtiesList];
    if (type === "deactivate") {
      let tempData = [];
      data.forEach((element, index) => {
        element.isDeleted = element.isActive;
        tempData.push(element);
      });
      data = tempData;
      const filterData = data.filter((item) => item.isActive === true);
      data = filterData;
    } else {
      let status = false;
      data.forEach((element, index) => {
        if (element.optionName.trim() === "") {
          status = true;
        }
      });
      if (status === true) {
        setShowSuccessPopup(true);
        setShowText("Can't able to update Null value!");
        return;
      }
    }

    setCatIsEdit(-1);
    setSubmitHandle(true);
    const res = await handleCategoryDataAction(data);
    if (res.status === 200) {
      // setHandleRefresh(new Date().getTime());
      handleChangeSpecialty(defaultSelected, categoryTitle);
      setShowSuccessPopup(true);
      setShowText("The modifications are successfully saved.");
      setOkText("update");
      setSaveCategory(false);
    }
  };
  const saveCategories = async () => {
    setCategoryMessage("");
    if (inputCategory.trim().length === 0) {
      setCategoryError(true);
      return;
    }
    setCategoryError(false);
    const data = {
      categoryId: defaultSelected,
      optionName: inputCategory,
      id: 0,
      isActive: true,
      isDeleted: false,
      createdBy: loggedInUserId,
      updatedBy: loggedInUserId,
      createDate: new Date(),
      updateDate: new Date(),
    };
    setSubmitHandle(true);

    const res = await saveCategoryDataAction(data);
    if (res.status === 200) {
      // handleAddNew(false);

      if (res && res.data.isSuccess === false) {
        setShowSuccessPopup(true);
        setOkText("dupilcate");
        setShowText("You are trying to save a duplicate option.");
        setCategoryMessage(res.data.message);
        return;
      }

      setInputCategory("");
      handleChangeSpecialty(defaultSelected, categoryTitle);
      setShowSuccessPopup(true);
      setShowText("The option is successfully added.");
      setOkText("add");
      // handleAddNew(false);
    }
  };

  const saveCategoryDataAction = (payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await post(
          `${BASE_URL_PRE_DEV}/api/saveadminoption`,
          payload
        );
        setSubmitHandle(false);
        resolve(res);
      } catch (err) {
        setSubmitHandle(false);
        reject(err);
      }
    });
  };
  const handleCategoryDataAction = (payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await put(
          `${BASE_URL_PRE_DEV}/api/updatealladminoptions`,
          payload
        );
        setSubmitHandle(false);
        resolve(res);
      } catch (err) {
        setSubmitHandle(false);
        reject(err);
      }
    });
  };
  const saveMainCategories = async () => {
    setSpecialtyMessage("");
    if (mainCategory.trim().length === 0) {
      setSpeciaLtyError(true);

      return;
    }
    setDisableAdd(false);
    setSpeciaLtyError(false);
    const data = {
      categoryName: mainCategory,
      id: mainCatId,
      modalName: "DemographicForm",
      isActive: true,
      isDeleted: false,
      createdBy: loggedInUserId,
      updatedBy: loggedInUserId,
      createDate: new Date(),
      updateDate: new Date(),
    };
    setSubmitHandle(true);
    const res = await saveMainCategoryDataAction(data);
    if (res.status === 200) {
      //"message"
      if (res && res?.data.isSuccess === false) {
        setShowSuccessPopup(true);
        setSpecialtyMessage(res.response.data.message);
        return;
      }
      setHandleRefresh(new Date().getTime());
      setSaveCategory(false);
      setMainCategory("");
    }
  };

  const saveMainCategoryDataAction = (payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await post(
          `${BASE_URL_PRE_DEV}/api/saveadmincategory`,
          payload
        );
        setSubmitHandle(false);
        resolve(res);
      } catch (err) {
        setSubmitHandle(false);
        reject(err);
      }
    });
  };

  const handleChangeSpecialty = async (id, title) => {
    if (typeof id === "undefined") {
      return;
    }
    setDefaultSelected(id);
    setCategoryTitle(title);
    setSubmitHandle(true);
    const res = await handleChangeSpecialtyAction(id);
    if (res.status === 200) {
      setSubmitHandle(false);

      if (res) {
        const hasActiveRecord = res.data.some((item) => item.isActive);
        if (hasActiveRecord) {
          setChangeItem(true);
        } else {
          setChangeItem(false);
        }
        setSpecialtiesList(res.data);
      }
    }
  };

  const handleChangeSpecialtyAction = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await get(
          `${BASE_URL_PRE_DEV}/api/getadminoptionsbycatid?catid=${id}`
        );
        setSubmitHandle(false);
        resolve(res);
      } catch (err) {
        setSubmitHandle(false);
        reject(err);
      }
    });
  };

  const handleSpecilityChange = (val, index) => {
    let tempSpecilty = [...specialtiesList];
    tempSpecilty[index]["optionName"] = val;
    setSpecialtiesList(tempSpecilty);
  };
  const handleCancelAction = () => {
    // setOpenModel(false);
    setExternalRedirect(false);
    handleAddNew(false);
    setInputCategory("");
    setSaveCategory(false);
  };
  const handleOkClick = () => {
    if (okText === "add") {
      handleAddNew(false);
      setShowSuccessPopup(false);
    }else {
      setShowSuccessPopup(false);
    }
  };
  return (
    <>
      {submitHandle && (
        <div className="transparent--loader">
          <div className="loading">
            <CircularProgress />
          </div>
        </div>
      )}

      <div className="contact-header-container"></div>
      <div className="categories--list" style={{ marginTop: "50px" }}>
        <Grid container spacing={2} style={{ padding: "15px" }}>
          <Grid item xs={3}>
            <div className="contact-header-childs contact-header-child1">
              <h2 className="main-title mb-btm20">Demographics</h2>
            </div>
            {/* {hasAdminPermission() && (
             
            )} */}
            <List className="categories-list-item">
              {specialtyCategory &&
                specialtyCategory.map((element) => (
                  <ListItem
                    key={element.id}
                    className={
                      defaultSelected === element.id
                        ? "selectedItem main--cat"
                        : "main--cat"
                    }
                  >
                    <ListItemText
                      primary={element.categoryName}
                      onClick={() =>
                        handleChangeSpecialty(element.id, element.categoryName)
                      }
                    />
                    {/* {!hasReadFieldPermission() && (
                        <span
                          className="cat--edit"
                          onClick={() => {
                            setSpeciaLtyError(false);
                            setSaveCategory(true);
                            setMainCategory(element.categoryName);
                            setMainCatId(element.id);
                            setDisableAdd(true);
                          }}
                        >
                          <EditIcon />
                        </span>
                      )} */}
                  </ListItem>
                ))}

              {/* {!hasReadFieldPermission() && (
                  <ListItem>
                    <Button
                      className={`custom-btn ${
                        disableAdd ? "disabledbtn" : ""
                      }`}
                      disabled={disableAdd ? true : false}
                      onClick={() => {
                        setSaveCategory(true);
                        setMainCategory("");
                        setDisableAdd(true);
                      }}
                    >
                      <AddIcon /> Add New
                    </Button>
                  </ListItem>
                )} */}
              {saveCategory && (
                <ListItem>
                  <div className="handleSaveCategory">
                    <label className="cat-label">
                      Demographic Title <span className="required">*</span>
                    </label>
                    <TextField
                      className={`catename ${speciaLtyError ? "b-error" : ""}`}
                      inputProps={{ maxLength: 50 }}
                      value={mainCategory}
                      onChange={(e) => {
                        setMainCategory(e.target.value);
                        setSpeciaLtyError(false);
                      }}
                    />
                    <div className="main-cat-btn">
                      <Button
                        className="custom-btn"
                        onClick={() => saveMainCategories()}
                      >
                        {mainCatId === 0 ? "Save" : "Update"}
                      </Button>
                      <Button
                        className="custom-btn"
                        onClick={() => {
                          handleCancelAction();
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                    {specialtyMessage && (
                      <p className="dup-error-message">{specialtyMessage}</p>
                    )}
                  </div>
                </ListItem>
              )}
            </List>
          </Grid>
          {/* {hasAdminPermission() && (
          
          )} */}
          <Grid item xs={9}>
            <Typography
              variant="h3"
              component="h3"
              className="sub--title  mb-btm20"
            >
              {categoryTitle}
            </Typography>
            <List className="categories-sublist">
              {specialtiesList &&
                specialtiesList.map((element, index) => (
                  <ListItem key={element.id}>
                    <Checkbox
                      value={element.id}
                      // disabled={hasReadFieldPermission()}
                      onChange={(e) =>
                        handleSingle(element.id, e.target.checked)
                      }
                      checked={element.isActive}
                    />

                    {catIsEdit === element.id ? (
                      <div className="updateSpecility">
                        <TextField
                          className={`catename ${
                            categoryError ? "b-error" : ""
                          }`}
                          inputProps={{ maxLength: 50 }}
                          label="Demographic Title"
                          value={element.optionName}
                          onChange={(e) => {
                            handleSpecilityChange(e.target.value, index);
                            setCategoryError(false);
                          }}
                        />
                        {/* <Button className="cancel-btn" onClick={() => {setCatIsEdit(-1); handleSave("save"); }}>
                        <DoneIcon />3
                      </Button>*/}
                      </div>
                    ) : (
                      <label
                        className="catergories--label"
                        onDoubleClick={() => {
                          if (catIsEdit !== -1 && checkBlankEdit() === true) {
                            alert("Please fill edit category title!");
                            return;
                          }
                          setCatIsEdit(element.id);
                          setChangeItem(true);
                        }}
                      >
                        {element.optionName}
                      </label>
                    )}
                  </ListItem>
                ))}
            </List>

            <div className="select-all">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectAll}
                    disabled={specialtiesList.length > 0 ? false : true}
                    onChange={() => {
                      handleSelectAll(!selectAll);
                      setChangeItem(true);
                    }}
                  />
                }
                label={"Select All"}
              />
            </div>
            <div className="button-group">
              <Button
                className="custom-btn"
                // disabled={hasReadFieldPermission()}
                onClick={() => handleAddNew(true)}
              >
                <AddIcon /> {"Add New"}
              </Button>
              <Button
                className={`custom-btn ${changeItem ? "" : "disabledbtn"}`}
                disabled={changeItem ? false : true}
                onClick={() => handleSave("save")}
              >
                {"Save"}
              </Button>
              <Button
                className={`custom-btn ${changeItem ? "" : "disabledbtn"}`}
                disabled={changeItem ? false : true}
                onClick={() => handleSave("deactivate")}
              >
                {"Deactivate"}
              </Button>
              {/* <Button
                  className="custom-btn"
                  disabled={hasReadFieldPermission()}
                  onClick={(e) => {
                    setOpenModel(true);
                    setExternalRedirect(true);
                  }}
                >
                  {"Cancel"}
                </Button> */}
            </div>
            {addNew && (
              <div className="handleSaveCategory">
                <label className="cat-label">
                  Option Name <span className="required">*</span>
                </label>
                <TextField
                  className={`catename ${categoryError ? "b-error" : ""}`}
                  value={inputCategory}
                  onChange={(e) => setInputCategory(e.target.value)}
                  inputProps={{ maxLength: 50 }}
                />
                <Button className="custom-btn" onClick={() => saveCategories()}>
                  {"Save"}
                </Button>
                <Button
                  className="custom-btn"
                  onClick={() => handleCancelAction()}
                >
                  {"Cancel"}
                </Button>
                {/* {categoryMessage && (
                  <p className="dupcat-error-message">{categoryMessage}</p>
                )} */}
              </div>
            )}
            <ModalPopup
              parentClassName="contactWithModal"
              show={showSuccessPopup}
              size={"sm"}
              onHide={() => setShowSuccessPopup(false)}
              centered={true}
              modelContent={
                <>
                  {" "}
                  <div>{showText}</div>
                </>
              }
              modelFooter={
                <div
                  style={{ justifyContent: "center" }}
                  className="ViewInvoices"
                >
                  <Button
                    style={{
                      marginRight: "10px",
                      background: "#000000",
                      padding: "5px 5px",
                      borderRadius: "6px",
                      color: "white",
                      width: "100px",
                      opacity: 1,
                    }}
                    onClick={handleOkClick}
                  >
                    Ok
                  </Button>
                </div>
              }
              backdrop={"static"}
              scrollable={false}
              closeButtonShow={false}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default DemographicItem;
