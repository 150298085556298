import React from "react";
import * as Utility from "../../utils/utility";
const CaseData = (props) => {
  return (
    <>
      <div className="row mb-0s">
        <div className="col-md-12">
          <div className="row">
            <div className="col-2">
              <label htmlFor="staticEmail" className="col-form-label float-start text-start leftt">
                Case Name
              </label>
            </div>
            <div className="col-10 nme">
              <span className="nme2">{props.caseDetails["caseName"]}</span>
            </div>
            <div className="">
              {/* <button className="float-right pmabtn" disabled={filebase64 ? false : true} onClick={downloadFile}> Download PMA Details</button> */}
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-1s">
        <div className="col-md-4">
          <div className="row">
            <div className="col-4">
              {" "}
              <label
                htmlFor="staticEmail"
                className=" col-form-label float-start text-start leftt"
              >
                NAM ID
              </label>
            </div>
            <div className="col-8 text-left">
              <span
 
                className="form-control-plaintext rightt"
 
              >{props.caseDetails["caseReference"]}</span>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="row">
            <div className="col-4">
              <label
                htmlFor="staticEmail"
                className=" col-form-label float-start text-start leftt"
              >
                Hearing Officer{" "}
              </label>
            </div>
            <div className="col-8 text-left">
              <span
                className="form-control-plaintext rightt"
                style={{ textAlign: "left" }}
              >{
                  props.caseDetails["hearingOfficerName"] !== undefined &&
                    props.caseDetails["hearingOfficerName"] !== null
                    ? props.caseDetails["hearingOfficerName"]
                    : ""
                }
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-1s">
        <div className="col-md-4">
          <div className="row">
            <div className="col-4">
              <label
                htmlFor="staticEmail"
                className=" col-form-label float-start text-start leftt"
              >
                Case Method
              </label>
            </div>
            <div className="col-8 text-left">
              <span
                className="form-control-plaintext rightt"
              >{props.caseDetails["caseType"]}
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="row">
            {props.caseDetails["caseType"] !== null &&
              props.caseDetails["caseType"] !== undefined &&
              props.caseDetails["caseType"].includes("Arbitration") &&
              props.caseDetails["caseMethod"] !== null &&
              props.caseDetails["caseMethod"] !== undefined &&
              props.caseDetails["caseMethod"] !== "" && (
                <>
                  <div className="col-4">
                    <label
                      htmlFor="staticEmail"
                      className=" col-form-label float-start text-start leftt"
                    >
                      Case Type
                    </label>
                  </div>
                  <div className="col-8 text-left">
                    <span
                      className="form-control-plaintext rightt"
                      style={{ textAlign: "left" }}
                    >
                      {props.caseDetails["caseMethod"] !== undefined &&
                        props.caseDetails["caseMethod"] !== null
                        ? props.caseDetails["caseMethod"]
                        : ""}
                    </span>
                  </div>
                </>
              )}
          </div>
        </div>
      </div>
      <div className="row mb-1s">
        <div className="col-md-4">
          <div className="row" style={{marginBottom:'1rem'}}>
            <div className="col-4">
              <label
                htmlFor="staticEmail"
                className=" col-form-label float-start text-start leftt"
              >
                Date of conference
              </label>
            </div>
            <div className="col-8 text-left">
              <span
                className="form-control-plaintext rightt"
              >{Utility.getdate(props.caseDetails["hearingStartTimeUTCFromCMS"])}
              </span>
            </div>
          </div>
        </div>
        {props.caseDetails["isCommercialCase"] && !Utility.IsHearingOfficer() && <div className="col-md-4">
          <div className="row">
            <div className="col-4">
              <label
                htmlFor="staticEmail"
                className=" col-form-label float-start text-start leftt"
              >
                Type
              </label>
            </div>
            <div className="col-8 text-left">
              <span
                className="form-control-plaintext rightt"
              >Monthly Billing
              </span>
            </div>
          </div>
        </div>}
        <div className="col-md-4">
          <div className="row">
            {Utility.getComapreDate(
              Utility.getdate(props.caseDetails["hearingDate"]),
              Utility.getdate(new Date())
            ) && (
                <>
                  {" "}
                  {props.caseDetails["hearingClosedDate"] !=='' &&
                  props.caseDetails["hearingClosedDate"] !==
                          undefined &&
                          props.caseDetails["hearingClosedDate"] !== null
                        &&(<>
                  <div className="col-4">
                    <label
                      htmlFor="staticEmail"
                      className=" col-form-label float-start text-start leftt"
                    >
                      Date Closed
                    </label>
                  </div>
                  <div className="col-8 text-left">
                    <span
                      className="form-control-plaintext rightt"
                    >{
                        props.caseDetails["hearingClosedDate"] !==
                          undefined &&
                          props.caseDetails["hearingClosedDate"] !== null
                          ? Utility.getdate(
                            props.caseDetails["hearingClosedDate"]
                          )
                          : "--"
                      }
                    </span>
                  </div>
                        </>
                        )}
                </>
              )}
          </div>
        </div>
      </div>
    </>
  );
};
 
export default CaseData;