export const CLIENT_ID = `NAM`;
export const CMSSYNC_LOGICAPP = `https://syncfunctionuat.azurewebsites.net/api/CopyFilesToCMS?code=sbD_CoUoYu3K0mluoyK1CwshnZ1uz-KHIB4k3xzfEllaAzFuI6IbxQ==`;
export const BASE_URL_FULCRUMONE_SSO_SERVICE = `https://fulcrumone-sso-service.pre-dev.neutralsportal.namadr.com`;
export const BASE_URL_NAM_HEARINGS_SERVICE = `https://nam-hearings-service.pre-dev.neutralsportal.namadr.com`;
export const BASE_URL_NAM_HEARINGOFFICERS_SERVICE = `https://nam-hearingofficers-service.pre-dev.neutralsportal.namadr.com`;
export const BASE_URL_FULCRUMONE_NOTIFICATIONS_SERVICE = `https://fone-notifications-svc.pre-dev.neutrals.namadr.com`;
export const BASE_URL_BLOB_SERVICE = `https://fulcrumone-fileblob-service.staging.neutralsportal.namadr.com`;
export const BASE_URL_DEMOGRPAHICS_SERVICE = `https://nam-demographics-service.pre-dev.neutralsportal.namadr.com`;
export const BASE_URL_FULCRUMONE_PDFGENERATE_SERVICE = `https://pdf-neutrals.namadr.com/staging`;
export const BASE_URL_TIMESHEET_SERVICE = `https://nam-timesheet-service.pre-dev.neutralsportal.namadr.com`;
export const BASE_URL_CMS_CASE_SERVICE = `https://cms-case-service.dev.cm2.namadr.com`;
export const BASE_FONE_FILEBLOB_SERVICE ="https://fulcrumone-fileblob-service.dev.cm2.namadr.com";
export const BASE_URL_PRE_DEV =`https://nam-demographics-service.pre-dev.neutralsportal.namadr.com`
export const CREATE_DEMOGRPAHICS = `${BASE_URL_DEMOGRPAHICS_SERVICE}/api/Demographics/SaveDemographic`
export const GET_DEMOGRPAHICS = `${BASE_URL_DEMOGRPAHICS_SERVICE}/api/Demographics/GetDemographicsByCaseCode`
export const GENERATE_TOKEN = `${BASE_URL_FULCRUMONE_SSO_SERVICE}/api/generateToken`;
export const REFRESH_TOKEN = `${BASE_URL_FULCRUMONE_SSO_SERVICE}/api/token/refresh`;
export const USER_ROLES = `${BASE_URL_FULCRUMONE_SSO_SERVICE}/api/GetUserRole?email=`;
export const CREATE_COMMON_USER = `${BASE_URL_FULCRUMONE_SSO_SERVICE}/api/Users`;
export const CHANGE_PASSWORD = `${BASE_URL_FULCRUMONE_SSO_SERVICE}/api/ChangePassword`;
export const GET_COLLABORATOR = `${BASE_URL_FULCRUMONE_SSO_SERVICE}/api/GetAllUser?role=`;
export const REVOKE = `${BASE_URL_FULCRUMONE_SSO_SERVICE}/api/token/revoke`;
export const FORGOT_PASSWORD = `${BASE_URL_FULCRUMONE_SSO_SERVICE}/api/forgotpassword`;
export const FORGOT_PASSWORD_WITH_CODE = `${BASE_URL_FULCRUMONE_SSO_SERVICE}/api/forgotpasswordwithcode`;
export const CHANGE_PASSWORD_WITH_CODE = `${BASE_URL_FULCRUMONE_SSO_SERVICE}/api/changepasswordwithcode`;
export const CUSTOM_EMAIL_NOTIFICATION = `${BASE_URL_FULCRUMONE_NOTIFICATIONS_SERVICE}/api/customemailnotification`;
export const VERIFY_ACCOUNT_WITH_CODE = `${BASE_URL_FULCRUMONE_SSO_SERVICE}/api/verifyaccountwithcode`;
export const HEARINGS = `${BASE_URL_NAM_HEARINGS_SERVICE}/GetAllHearings`;
export const HearingsByNeutral = `${BASE_URL_NAM_HEARINGS_SERVICE}/HearingsByNeutral`;
export const HEARINGS_BY_NEUTRAL = `${BASE_URL_NAM_HEARINGS_SERVICE}/HearingsByNeutral`;
export const HEARING_DETAILS = `${BASE_URL_NAM_HEARINGS_SERVICE}/HearingDetails`;
export const HEARINGDETAILS = `${BASE_URL_NAM_HEARINGS_SERVICE}/GetHearingDetailsByHearingCode`;
export const PMA_DETAILS = `${BASE_URL_NAM_HEARINGS_SERVICE}/PMADetails`;
export const ADD_PMA_DETAILS = `${BASE_URL_NAM_HEARINGS_SERVICE}/AddPMA`;
export const UpdateHearingData = `${BASE_URL_NAM_HEARINGS_SERVICE}/UpdateHearingData`
export const ALLHEARING_OFFICERS = `${BASE_URL_NAM_HEARINGOFFICERS_SERVICE}/HearingOfficers/GetAllHearingOfficers`;
export const HEARINGOFFICER_BYEMAIL = `${BASE_URL_NAM_HEARINGOFFICERS_SERVICE}/HearingOfficers/GetHearingOfficerByEmail`;
export const HEARINGOFFICER_BYID = `${BASE_URL_NAM_HEARINGOFFICERS_SERVICE}/HearingOfficers/GetHearingOfficerById`;
export const INSERT_FILE = `${BASE_URL_BLOB_SERVICE}/api/insertfile`;
export const INSERT_DECISION_FILE = `${BASE_URL_BLOB_SERVICE}/api/insertdecisionfile`;
export const INSERT_CASE_FILE = `${BASE_URL_BLOB_SERVICE}/api/insertcasefile`;
export const GET_ALL_PENDINGTASKS = `${BASE_URL_NAM_HEARINGS_SERVICE}/GetAllPendingTasks`;
export const GET_PENDINGTASKS_BY_NEUTRAL = `${BASE_URL_NAM_HEARINGS_SERVICE}/PendingHearingsByNeutral`;
export const GET_TIMESHEET_FILEWITHSAS = `${BASE_URL_BLOB_SERVICE}/api/GetTimesheetFileByteWithSAS`;
export const GET_FILEWITHSAS = `${BASE_URL_BLOB_SERVICE}/api/GetFileBytewithSAS`;
export const GET_DECISION_FILEWITHSAS = `${BASE_URL_BLOB_SERVICE}/api/GetDecisionFilewithSAS`;
export const GET_MULTIPLE_UPLOADED_FILE = `${BASE_URL_BLOB_SERVICE}/api/GetMultipleUploadFilefromStorage`;
export const GET_DOCUSIGNFILE = `${BASE_URL_BLOB_SERVICE}/api/GetDocusignFile`;
export const GET_PENDINGTASKS_BY_NEUTRAL_EMAIL = `${BASE_URL_NAM_HEARINGS_SERVICE}/GetPendingHearingsByNeutralEmail`;
export const BASE_URL = `https://pre-dev.neutralsportal.namadr.com/`;
export const HTML_TO_PDF = `${BASE_URL_FULCRUMONE_PDFGENERATE_SERVICE}/api/convertstringtohtml`;
export const GET_INVOICEFORNEUTRAL = `${BASE_URL_NAM_HEARINGS_SERVICE}/GetInvoiceForNeutral`;
export const GET_PENDINGCOUNTFORNEUTRAL = `${BASE_URL_NAM_HEARINGS_SERVICE}/PendingHearingsCountByNeutral`;
export const DOCUSIGN_CONNECTOR = `https://docusignconnector-uat.azurewebsites.net/api/DocusignConnectorDev?code=SsVMKK67ps7Tj-9V89JA7X1M7kiT8oDsalSuK8tpbj-tAzFuFEFN3g==`;
export const GET_ALLPENDING = `${BASE_URL_NAM_HEARINGS_SERVICE}/AllPendingHearings`;
export const UPDATE_PENDINGTASKS = `${BASE_URL_NAM_HEARINGS_SERVICE}/UpdatePendingTask`;
export const UPDATE_PENDINGTASKSFOR_DOCUMENT = `${BASE_URL_NAM_HEARINGS_SERVICE}/RemovePendingTaskForDocument`;
export const GET_CASESUBMISSIONS = `${BASE_URL_NAM_HEARINGS_SERVICE}/GetSubmissionsByCase`;
export const DELETE_RESPONSE = `${BASE_URL_BLOB_SERVICE}/deletefile`;
export const DELETE_PMA_RESPONSE = `${BASE_URL_NAM_HEARINGS_SERVICE}/DeletePMA`;
export const MERGE_PMA_RESPONSE = `${BASE_URL_FULCRUMONE_PDFGENERATE_SERVICE}/api/MergeMultipleFileUpload`;
export const MERGE_DECISIONPMA_RESPONSE = `${BASE_URL_FULCRUMONE_PDFGENERATE_SERVICE}/api/DecisionPamMultipleFileUpload`;
export const GET_INVOICE_STATEMENTS = `${BASE_URL_BLOB_SERVICE}/api/GetStatements`;
export const GET_TRAINING_VIDEOS = `${BASE_URL_BLOB_SERVICE}/api/GetTrainingVideos`;
export const DELETE_PMA_ADDITIONAL_RESPONSE = `${BASE_URL_NAM_HEARINGS_SERVICE}/DeleteAdditionalResponsePMA`;
export const DELETE_ADDITIONAL_RESPONSE = `${BASE_URL_BLOB_SERVICE}/DeleteAdditionalFile`;
export const MERGE_MULTIPLE_PDF=`${BASE_URL_FULCRUMONE_PDFGENERATE_SERVICE}/api/mergemultiplefiles`;
export const CMS_CASE_DETAILS =`${BASE_URL_CMS_CASE_SERVICE}/api/getallcaseemployeelist`;
export const CMS_SAVE_DIARY =`${BASE_URL_CMS_CASE_SERVICE}/api/savediarydata`;
export const UPLOAD_MULTIPLE_FILES = BASE_FONE_FILEBLOB_SERVICE + "/api/uploadmultiplefiles";
export const DELETE_FILE_BLOB =BASE_FONE_FILEBLOB_SERVICE + "/api/deletemultiplefiles";
export const COPY_FILES_BLOB = BASE_FONE_FILEBLOB_SERVICE + "/api/copyfiles";
export const COPY_FILES_FROM_CMS1 =BASE_FONE_FILEBLOB_SERVICE + "/api/copyfilesfromcms1";
export const GET_FILE_DOWNLOADURL_BLOB =BASE_FONE_FILEBLOB_SERVICE + "/api/getfiledownloadurl";
export const GET_HEARING_DATA_BY_HEARING_CODE=
BASE_URL_NAM_HEARINGS_SERVICE+"/gethearingdatabyhearingcode"
export const GET_BASE64_FILE =
  BASE_FONE_FILEBLOB_SERVICE + "/api/viewfileasbase64";
  export const GET_All_DEMOGRAPHICS_OPTION =
  BASE_URL_DEMOGRPAHICS_SERVICE + "/api/getalldemographicoptions";
    export const ADD_DEMOGRAPHICS_EXEMPTION_CASE=
BASE_URL_NAM_HEARINGS_SERVICE+"/Hearings/adddemographicexemptionincase"
export const UPDATE_CMSDEMOGRPAHICS = `https://pmasync-dev.azurewebsites.net/api/demographicsSyncing?code=cNgFsypYeXI5nyCGnT1dw208nN-xS7wRaJpKBIm2JWyrAzFuuZ30vQ==`
export const UPDATE_CMSTIMESHEET = `https://pmasync-dev.azurewebsites.net/api/timesheetSyncing?code=aj7AMRqp3FoAGg2bYqYbvivOipF3YBPjGrIUEMVDpW_6AzFuMUzeMQ==`;
export const UPDATE_CMSPMA = `https://pmasync-dev.azurewebsites.net/api/PmaSyncing?code=XGxBrxj4tq8YVIkOltMF5-nkadRfv1SRfffpGea_kX9AAzFudupatw==`;
export const LOGIN_SYNC = `https://pmasync-dev.azurewebsites.net/api/LoginSyncing?code=uJnrgJlsO5GLRP0_mpbZndAtcLIFQjFunYp1Pjq0HfbWAzFubdQtNw==`;
export const CASE_SYNC = `https://pmasync-dev.azurewebsites.net/api/LoginSyncing?code=uJnrgJlsO5GLRP0_mpbZndAtcLIFQjFunYp1Pjq0HfbWAzFubdQtNw==`;
export const CMSSYNC = `https://pmasync-dev.azurewebsites.net/api/UserDataSync?code=5K9Dn3F6d4BK3mo_G33oVhd8P2qEf7lpThQgdBwtClyKAzFujjVzLA==`;
export const isMask = true;
export const isSyncBack = true;
export const env = "pre-dev";
export const BIG_4_NEUTRALS = [];
export const ZOOMMEETING_DETAILS = `${BASE_URL_NAM_HEARINGS_SERVICE}/GetZoomMeetingByHearingCode`;
export const TIMESHEET_DETAILS = `${BASE_URL_TIMESHEET_SERVICE}/GetAllTimesheetDetails`;
export const ADD_TIMESHEET_DETAILS = `${BASE_URL_TIMESHEET_SERVICE}/AddTimesheet`;
export const INSERT_PENDING_TIMESHEET_DETAILS = `${BASE_URL_TIMESHEET_SERVICE}/InsertTimesheetInPendingTask`;
export const UPDATE_PENDING_TIMESHEET_DETAILS = `${BASE_URL_TIMESHEET_SERVICE}/UpdatePendingStatusAsync`;
export const GETTIMESHEET_DETAILS_BY_CASECODE = `${BASE_URL_TIMESHEET_SERVICE}/GetTimesheetDetails`;
export const mailTimesheet = `sneha.agashe@fulcrumdigital.com`
export const mailSchedule = `scheduling@namadr.com`
export const mailAccount = `accounting@namadr.com`
export const mailMask = `sneha.agashe@fulcrumdigital.com`
export const UPDATE_LOGGEDIN_DETAILS = `${BASE_URL_NAM_HEARINGS_SERVICE}/UpdateLoggedInUserDetails`
export const GRAMERLY_CLIENT_ID = 'client_PkEwy9Ct6LmqLoorGewrtL'
export const EMAILCUSTOMERSERVICE = 'customerservice@namadr.com'
export const PHONECUSTOMERSERVICE = '1-800-358-2550'
export const GRAMERLY_ASSERTION_ID = "https://nam-staging-grammarly.azurewebsites.net/"
export const FORGOT_PASSWORD_EMAIL_SUBJECT="NAM Neutrals Portal Forgot Password";
export const COMMERCIAL_EMAIL_SUBJECT="Timesheet for NAM ID: ${caseReference} submitted for Review";
export const COMMERCIAL_REVIEW_EMAIL_SUBJECT="Timesheet for NAM ID: ${caseReference} clarification required";
export const MASK_PMA_PARTICIPANTS=false;
export const EDIT_NEUTRAL_EMAIL=false;
export const HEARING_DURATION_LIMIT=360;
export const IDLE_TIMEOUT=1200000;
export const REALTIME_DATA=false;
export const IsMaintenance =false; // Maintainance Page flag
export const secKey="b14ca5898a4e4133bbce2ea2315a1916";
export const voidedDocusign="https://docusignconnectortraining.azurewebsites.net/api/VoidEnvelope?code=7RlLWaX69jgzuIXV0q9OXCqrwi1-HNiDBdlc1XUi41MpAzFuK9fxsg==";



export const APPLICATION_URL ="https://pre-dev.neutralsportal.namadr.com/#/"


