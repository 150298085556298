/* eslint-disable default-case */
import React from "react";
import "./header.scss";
import F1HeaderNav from "f1-header-navigation-react";
import logo from "../../assets/img/logoSqr.jpg";
import namlogo from "../../assets/img/namlogo.png";
import Group from "../../assets/img/Group.svg";
import Button from "@material-ui/core/Button";
import { TextField, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import axios from "axios";
import { CHANGE_PASSWORD, BIG_4_NEUTRALS } from "../../api/baseURL";
import * as Utility from "../../utils/utility";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Dropdown from 'react-bootstrap/Dropdown';
import MenuItem from '@mui/material/MenuItem';
import LockIcon from '@mui/icons-material/Lock';
import LogoutIcon from '@mui/icons-material/Logout';
import { decryptItem } from "../../shared/Encrypt";
const error = "";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
//const email = decryptItem("neutralsEmail");

class Header extends React.Component {
  constructor() {
    super();    
    this.state = {
      timeout: 1000 * 1800 * 1,
      showModal: false,
      userLoggedIn: false,
      isTimedOut: false,
      selectedProduct: 1,
      showPassword: false,
      isActiveCases: false,
      isActiveCalendar: false,
      isActivePending: false,
      isActiveContactUs: false,
      isActiveInvoices: false,
      isActiveCategories:false,
      oldPass: "",
      newPassFirst: "",
      newPassSecond: "",
      loading: false,
      [error]: true, // error status for each specific field
      form: {}, // output form with data
      open: false,
      variant: "",
      message: "",
      pendingTaskCount: `Pending Tasks
        ${localStorage.getItem("pendingTaskCount") !== undefined &&
          localStorage.getItem("pendingTaskCount") !== null
          ? localStorage.getItem("pendingTaskCount")
          : ""
        }`,
      showInvoice: Utility.IsHearingOfficer() ? BIG_4_NEUTRALS.includes(parseInt(decryptItem ("jCode"))) : false
    };
  }
  componentDidMount() {
    this.setCurrentTab();
  }
  ////////////////////
  logout = () => {
    this.props.history.push("/");
    window.localStorage.clear();
    localStorage.clear();
  };

  logoClick = () => {
    this.props.history.push("/home");
  };
  changePasswordClick = () => { console.log("change event") };

  onRedirect = () => {
    this.props.history.push("/cases");
  };

  onItemClick = (item, childItem) => {
    if (childItem && childItem.id === "logout") {
      window.onbeforeunload = function () {
        localStorage.clear();
        localStorage.clear();
      };
      this.props.history.push("/");
      window.location.reload();
    } else if (childItem && childItem.id === "changePassword") {
      this.setState({ showPassword: true });
    } else if (childItem && childItem.id === "login") {
      this.props.history.push("/login");
    } else if (item && item.id === "cases") {
      this.props.history.push("/cases");
      //  window.location.reload();
    } else if (item && item.id === "calendar") {
      this.props.history.push("/CaseCalendar");
      //  window.location.reload();
    } else if (item && item.id === "pendingTasks") {
      this.props.history.push("/pendingTasks");
      //  window.location.reload();
    } else if (item && item.id === "invoices") {
      this.props.history.push("/Statements");
      // window.location.reload();
    } else if (item && item.id === "contactus") {
      this.props.history.push("/HelpDesk");
      // window.location.reload();
    }
    else if (childItem && childItem.id === "DemographicItem") {
      this.props.history.push("/demographicitem");
      // window.location.reload();
    }
    else if (childItem && childItem.id === "TimeSheetLineItem") {
      this.props.history.push("/timesheetlineitem");
      // window.location.reload();
    }
  };

  brandImgStyle = { width: "90px" };
  navBarStyle = { backgroundColor: "#225cb2" };
  navBarSimpleStyle = { backgroundColor: "#A9A9A9" };

  setCurrentTab = async () => {
    document.getElementById("pendingTasks").classList.remove("active");
    const currTab = window.location.href;
    let landing = currTab.split("#/")[1];
    if (landing && landing.toLowerCase().includes("casedetails")) {
      const hearingDetails= JSON.parse(decryptItem("hearingDetails"));
      const tabCheck = hearingDetails.goBack;
      landing =
        tabCheck !== undefined && tabCheck.length > 3 ? tabCheck : "cases";
    }
    landing = landing ? landing : "cases"//landing undefined very first time
    switch (landing.toLowerCase()) {
      case "cases":
        await this.setState({ isActiveCases: true });
        break;
      case "calendar":
      case "casecalendar":
        await this.setState({ isActiveCalendar: true });
        break;
      case "pendingTasks":
      case "pendingtasks":
        document.getElementById("pendingTasks").classList.add("active");
        await this.setState({ isActivePending: true });
        break;
      case "invoices":
      case "Statements":
      case "statements":
        await this.setState({ isActiveInvoices: true });
        break;
      case "contactus":
        case "helpdesk":
        await this.setState({ isActiveContactUs: true });
        break;
        case "DemographicItem" || "TimeSheetLineItem":
          await this.setState({isActiveCategories:true})
          break
    }
  };

  changePassword = async (data) => {
    const req = {};
    req["email"] = decryptItem("neutralsEmail");
    req["oldPassword"] = data.oldPassword;
    req["newPassword"] = data.newPassword1;
    const url = `${CHANGE_PASSWORD}?email=${decryptItem("neutralsEmail")}&oldPassword=${data.oldPassword}&newPassword=${data.newPassword1}`;
    axios
      .post(url, req)
      .then((res) => {
        if (res.data === true) {
          this.setState({
            loading: false,
            message: "Password changed successfully!",
            variant: "success",
            open: true,
          });
        } else {
          this.setState({
            loading: false,
            message: res.data,
            variant: "error",
            open: true,
          });
        }
      })
      .catch((error1) => {
        // this.setState({
        //   loading: false,
        //   message: "There is an error.Please try again later.",
        //   variant: "error",
        //   open: true,
        // });

        // console.log(error1);
      });
  };

  validateFields = (form) => {
    if (!form.oldPass || !form.newPassFirst || !form.newPassSecond) {
      Object.entries(form).forEach((item, index) =>
        [0, 1, 2, 3].includes(index) && !item[1]
          ? this.setState({ [`error${item[0]}`]: true })
          : this.setState({ [`error${item[0]}`]: false })
      );
      this.setState({
        message: `Fill in the field - ${`${!form.oldPass ? "Current password, " : ""
          }${!form.newPassFirst ? "New password, " : ""}${!form.newPassSecond ? "Repeat new password, " : ""
          }`
          .trim()
          .replace(/,$/g, "")}!`,
        variant: "warning",
        open: true,
      });
      return true;
    }

    if (
      form.newPassFirst &&
      form.newPassSecond &&
      form.newPassFirst !== form.newPassSecond
    ) {
      this.setState({
        message: "New password and retype new password do not match",
        variant: "warning",
        open: true,
      });
      return true;
    }

    if (form.oldPass && form.oldPass === form.newPassFirst) {
      this.setState({
        message: "New password and current password match",
        variant: "warning",
        open: true,
      });
      return true;
    }

    if (form.newPassFirst.length < 6 || form.newPassFirst.length > 16) {
      this.setState({
        message: "New password length should be between 6 and 16 characters.",
        variant: "warning",
        open: true,
      });
      return true;
    }
    return false;
  };

  handleSubmit = (e) => {
    this.submit();
  };

  async submit() {
    const { form } = this.state;
    const data = {
      userName: form.userName,
      oldPassword: form.oldPass,
      newPassword1: form.newPassFirst,
      newPassword2: form.newPassSecond,
    };
    let errorMessage;

    if (this.validateFields(form)) {
      return;
    }
    try {
      this.setState({ loading: true });
      await this.changePassword(data);
    } catch (error2) {
      errorMessage = error2.response.data;
    }

    if (errorMessage) {
      this.setState({
        loading: false,
        message: errorMessage,
        variant: "error",
        open: true,
      });
    }
  }

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.handleButtonClick();
    }
  };

  // checks the empty value of required fields
  clickField(field, value) {
    !value && this.setState({ [`error${field}`]: true });
  }
  // removes red warning from empty values of required fields when focus is lost
  blurField(field) {
    this.setState({ [`error${field}`]: false });
  }

  // value entry monitoring + empty value validation
  setFieldValue(field, value) {
    !value
      ? this.setState({ [`error${field}`]: true })
      : this.setState({ [`error${field}`]: false });
    const newForm = {
      ...this.state.form,
      [field]: value,
    };

    this.setState({
      form: newForm,
    });
  }

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ open: false });
  };
  pendingTaskClick = async (event) => {
    event.preventDefault();
    await this.setState({ isActivePending: true });
    this.props.history.push("/pendingTasks");
  };

  renderCustomeIcon = (item) => {
    return (
      <li
        id="pendingTasks"
        className="nav-item home"
        onClick={this.pendingTaskClick}
        key={item.id}
      >
      
        <a
          data-test="nav-link"
          className="nav-link Ripple-parent nav-link Ripple-parent"
          href="/"
          style={{ display: "flex" }}
        >
          Pending Tasks
          {localStorage.getItem("pendingTaskCount") !== undefined &&
            localStorage.getItem("pendingTaskCount") !== null &&
            parseInt(localStorage.getItem("pendingTaskCount")) > 0 && (
              <span className="circleBase2" data-count="1">
                {localStorage.getItem("pendingTaskCount")}
              </span>
            )}
        </a>
      </li>
    );
  };
  render() {
    const { oldPass, newPassFirst, newPassSecond } = this.state.form;
    // display diagnostic messages
    const snackbarMessage = (
      <Snackbar
        open={this.state.open}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={3000}
        onClose={this.handleClose}
        className="drawer-snackbar"
      >
        <Alert severity={this.state.variant}>{this.state.message}</Alert>
      </Snackbar>
    );
    return parseInt(localStorage.getItem("signinAttempt")) === 0 ? (
      <></>
    ) : (
      <div>
        <F1HeaderNav
          config={{
            navBarStyle: this.navBarStyle,
            navBarClassName: "my-custom-nav-bar",
            brandType: "text", // text/image/component, required
            brandText: (
              <span className="branding" onClick={this.onRedirect}>
                <img
                  alt=""
                  src={logo}
                  style={{ width: "35px", height: "35px", display: "none" }}
                />
                <img
                  alt=""
                  className="logoBranding"
                  src={namlogo}
                />
              </span>
            ),
            brandLink: "",
            brandImgUrl: "", // in case of type === image
            brandClass: "my-brand", // custom class for brand styling
            brandStyle: this.brandImgStyle, // style to be apply on brand component
            disableHambergerIcon: true, // default true
            onItemClick: this.onItemClick,
            menus: {
              right: [
                {
                  type: "link", // link/dropdown
                  label: "Hearings",
                  icon: "",
                  href: "",
                  // in case of dropdowns
                  is_active: this.state.isActiveCases,
                  className: "home hearings",
                  id: "cases",
                },
                {
                  type: "link", // link/dropdown
                  label: "Calendar",
                  icon: "",
                  href: "",
                  // in case of dropdowns
                  is_active: this.state.isActiveCalendar,
                  className: "home",
                  id: "calendar",
                },
                {
                  type: "link", // link/dropdown
                  label: this.state.pendingTaskCount,
                  icon: "",
                  href: "",
                  // in case of dropdowns
                  is_active: this.state.isActivePending,
                  className: "home pendingTasks",
                  id: "pendingTasks",
                  onItemRender: this.renderCustomeIcon,
                },
                {
                  type: "link", // link/dropdown
                  label: "Statements",
                  icon: "",
                  href: "",
                  // in case of dropdowns
                  is_active: this.state.isActiveInvoices,
                  className: "home",
                  id: "invoices",
                  hide: this.state.showInvoice
                },
                
                ...(!Utility.IsHearingOfficer()
                ? [
                    {
                      type: "dropdowns",
                      label: "Admin",
                      icon: "",
                      className: "LogoutDiv",
                      childs: [
                        {
                          label: "Demographic",
                          href: "",
                          className: "custom-option",
                          id: "DemographicItem",
                        },
                        {
                          label: "TimeSheet LineItem",
                          href: "",
                          className: "custom-option",
                          id: "TimeSheetLineItem",
                        },
                      ],
                      is_active: this.state.isActiveCategories,
                    },
                  ]
                : []),
              
                {
                  type: "link", // link/dropdown
                  label: "Help",
                  icon: "",
                  href: "",
                  // in case of dropdowns
                  is_active: this.state.isActiveContactUs,
                  className: "home contactus",
                  id: "contactus",
                },
               
                {
                  type: "link", // link/dropdown
                  label: "",
                  icon: "",
                  href: "",
                  // in case of dropdowns

                  className: "notifDiv",
                  id: "filler",
                },
                {
                  type: "dropdowns", // link/dropdown
                  label: `Welcome ${localStorage.getItem("fname")}`,
                  icon: "user profUser",
                  className: "LogoutDiv",
                  childs: [
                    {
                      label: `  ${localStorage.getItem("name")}`,
                      href: "",
                      className: "custom-option",
                      icon: "user",
                      id: "email",
                    },
                    {
                      label: " Change Password",
                      href: "",
                      className: "custom-option",
                      icon: "lock",
                      id: "changePassword",
                    },
                    {
                      label: " Logout",
                      href: "",
                      className: "custom-option",
                      icon: "sign-out",
                      id: "logout",
                    },
                  ], // in case of dropdowns
                  is_active: false,
                },
              ],
            },
          }}
        //// brandComponent={<this.CustomBrand />} // in case of type === component
        />
        <Dropdown className="profileMenu">
          <Dropdown.Toggle id="dropdown-autoclose-true">
            <AccountCircleIcon /> Welcome {localStorage.getItem("fname")}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <MenuItem><AccountCircleIcon /> Welcome {localStorage.getItem("fname")}</MenuItem>
            <MenuItem onClick={() => this.setState({ showPassword: true })}><LockIcon /> Change Password</MenuItem>
            <MenuItem onClick={() => this.logout()}><LogoutIcon /> Logout</MenuItem>
          </Dropdown.Menu>
        </Dropdown>
        <div
          className={
            this.state.showPassword ? "custom--drawer open" : "custom--drawer"
          }
        >
          <div className="myProfile">
            <div
              className="close"
              onClick={() => this.setState({ showPassword: false })}
            >
              x
            </div>
            <h1 style={{ fontSize: "1.2rem" }}>Change Password</h1>
            <div className="profileName">
              {/* <AccountCircleIcon /> */}
              <img
                src={Group}
                alt=""
                style={{ width: "50px", marginRight: "15px" }}
              />
              <div style={{ marginTop: "0.70rem" }}>
                <span
                  style={{
                    float: "left",
                    clear: "left",
                  }}
                >
                  {localStorage.getItem("name")}
                </span>

                {/* <span
                  className="proemail"
                  style={{
                    float: "left",
                    clear: "left",
                  }}
                >
                  {decryptItem("neutralsEmail")}
                </span> */}
              </div>
            </div>
            <div className="formDiv">
              <form onSubmit={this.handleSubmit} noValidate autoComplete="off">
                <div className="col-12">
                  <TextField
                    label="Current Password"
                    style={{ margin: 8 }}
                    // placeholder="Current Password"
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      minLength: 6,
                      maxLength: 10
                    }}
                    error={this.state.erroroldPass}
                    onClick={(e) => this.clickField("oldPass", e.target.value)}
                    onBlur={() => this.blurField("oldPass")}
                    onChange={(e) => {
                      this.setFieldValue("oldPass", e.target.value);
                    }}
                    type="password"
                    value={oldPass}
                  />
                </div>
                <div className="col-12">
                  <TextField
                    label="New Password"
                    style={{ margin: 8 }}
                    // placeholder="New Password"
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                      className: "custom-label",
                    }}
                    onClick={(e) =>
                      this.clickField("newPassFirst", e.target.value)
                    }
                    onBlur={() => this.blurField("newPassFirst")}
                    onChange={(e) => {
                      this.setFieldValue("newPassFirst", e.target.value);
                    }}
                    inputProps={{
                      minLength: 6,
                      maxLength: 10
                    }}
                    type="password"
                    value={newPassFirst}
                    error={this.state.errornewPassFirst}
                  />
                </div>
                <div className="col-12">
                  <TextField
                    label="Retype New Password"
                    style={{ margin: 8 }}
                    // placeholder="Retype New Password"
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onClick={(e) =>
                      this.clickField("newPassSecond", e.target.value)
                    }
                    onBlur={() => this.blurField("newPassSecond")}
                    onChange={(e) => {
                      this.setFieldValue("newPassSecond", e.target.value);
                    }}
                    inputProps={{
                      minLength: 6,
                      maxLength: 10
                    }}
                    type="password"
                    value={newPassSecond}
                    error={this.state.errornewPassSecond}
                  />
                </div>
                <div className="col-12 update-password">
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    style={{ textTransform: "unset !important" }}
                  >
                    Update Password
                  </Button>
                </div>
              </form>
              {snackbarMessage}
              {/* <p className="success">Your password has been updated successfully!</p>*/}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
