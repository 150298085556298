import React, { useState } from "react";
import * as Utility from "../../utils/utility";
import {
  Button,
  Box,
  IconButton,
  Typography,
  CircularProgress,
  Grid,
} from "@mui/material";
import { Modal as MaterialModal } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { post } from "../../api/api";
import {
  APPLICATION_URL,
  BASE_URL,
  BASE_URL_FULCRUMONE_NOTIFICATIONS_SERVICE,
  BASE_URL_NAM_HEARINGS_SERVICE,
  CMS_CASE_DETAILS,
} from "../../api/baseURL";

import namlogo from "../../assets/img/namlogo.png";
const CaseDataDemographics = (props) => {
  const [selectedcasesPopup, setselectedcasesPopUp] = useState(false);
  const [isFirstCall, setIsFirstCall] = useState(false);
  const [issubmitted, setIsSubmitted] = useState(false);
  const [checked, setChecked] = useState(
    props.hearingData.demographicExemption.isExemptionRequestRaised
  );
  const style2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "1px solid #fff",
    borderRadius: "6px",
    boxShadow: 24,
    p: 4,
  };

  const [exemptionReason, setExemptionReason] = useState("");

  const [aeCMEmail, setAECMEmail] = useState([]);
  const handleChangeTextArea = (event) => {
    setExemptionReason(event.target.value);
  };

  const onCheckboxChange = async (e) => {
    setChecked(!checked);
    if (e.target.checked === true) {
      setselectedcasesPopUp(true);
      if (isFirstCall === false) {
        setIsFirstCall(true);
        const res = await getCaseData();
        if (res.status === 200) {
          const listEmailReceivers = res.data.map((contact) => ({
            receiverName: contact.name,
            receiverEmail: contact.email,
            empId: contact.id,
            emailBodyVariables: {
              NAMEmployeeName: contact.name,
              NeutralName: localStorage.getItem("hearingOfficerName"),
              CaseID: props?.caseDetails.caseReference,
              NeutralReasonforExemption: "",
            },
          }));

          setAECMEmail(listEmailReceivers);
        }
      }
    }
  };

  const handleSave = async () => {
    const payload = {
      hearingcode: props?.caseDetails.hearingCode,
      reason: exemptionReason,
    };
    setSubmitHandle(true);
    const res = await saveCategoryDataAction(payload);
    if (res.status === 200) {
      sendEmailNotificationForContinuedHearing();
    }
  };

  const sendEmailNotificationForContinuedHearing = async () => {
    let subject = `Exemption Request for Demographics Form - Case ID: ${props?.caseDetails.caseReference}`;

    const updatedReceivers = aeCMEmail.map((item) => ({
      receiverName: item.receiverName,
      receiverEmail: item.receiverEmail,
      emailBodyVariables: {
        ...item.emailBodyVariables,
        NeutralReasonforExemption: exemptionReason,
        empId: item.empId.toString(),
      },
    }));
    const emailBody = {
      listEmailReceivers: updatedReceivers,
      emailSubject: subject,
      emailTemplate: `<!DOCTYPE html><html lang="en"><head><title>Email Template</title></head><body style="font-family: Arial, sans-serif; line-height: 1.6; color: #333;"><p style="font-weight: bold;">Dear NAMEmployeeName,</p>
<p>Neutral NeutralName has submitted an exemption request for the demographics form for the case<strong> Case ID: CaseID</strong>.</p>
<p style="font-weight: bold;">Reason for Exemption: <span style="font-weight: normal;">NeutralReasonforExemption</span></p>
<p style="font-weight: bold;">Two action links:</p><ul style="padding-left: 20px;"><li><a href="${APPLICATION_URL}caseStatus/${props?.caseDetails.hearingCode}/1/empId" style="text-decoration: none; font-weight: bold;">Approve</a> (Link to approve the exemption request)</li>
<li><a href="${APPLICATION_URL}caseStatus/${props?.caseDetails.hearingCode}/0/empId" style="text-decoration: none; font-weight: bold;">Deny</a> (Link to deny the exemption request)</li>
</ul><p>Please review the request and take action by either approving or denying the exemption.</p><p>Thank you,</p><p>NAM Neutral Portal Customer Service</p><p>+1 (800) 358-2550</p><img src="${BASE_URL}${namlogo}" width="310px"></img><p><strong>CONFIDENTIALITY NOTICE:</strong> The information contained in this message, and in any accompanying documents, may be proprietary, confidential, and/or privileged information which belongs to NAM (National Arbitration and Mediation). This information is intended only for the use of the individual or entity to whom it is addressed. If you are not the intended recipient of this email, or a person who is responsible for delivering emails to such intended recipient, you are hereby notified that any disclosure, copying, distribution, or the taking of any action in reliance on this information, is strictly prohibited. If you have received this message in error, please immediately notify the sender by email and delete the original message.<p/></div></body></html>
`,
      filename: "",
      cc: "",
      bcc: "",
    };

    const res = await sendMailToAllParticipants(emailBody);
    if (res.status === 200) {
      setselectedcasesPopUp(false);
      setExemptionReason("");
      setIsSubmitted(true);
    }
  };

  const sendMailToAllParticipants = (payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await post(
          `${BASE_URL_FULCRUMONE_NOTIFICATIONS_SERVICE}/api/customemailwithbodyvariables`,
          payload
        );
        setSubmitHandle(false);
        resolve(res);
      } catch (err) {
        setSubmitHandle(false);
        reject(err);
      }
    });
  };
  const getCaseData = (payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await post(`${CMS_CASE_DETAILS}?CaseId=1000275971`);
        setSubmitHandle(false);
        resolve(res);
      } catch (err) {
        setSubmitHandle(false);
        reject(err);
      }
    });
  };

  const saveCategoryDataAction = (payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await post(
          `${BASE_URL_NAM_HEARINGS_SERVICE}/Hearings/raiseexemptionrequestincase?HearingCode=${payload.hearingcode}&Reason=${payload.reason}`,
          {}
        );
        // setSubmitHandle(false);
        resolve(res);
      } catch (err) {
        setSubmitHandle(false);
        reject(err);
      }
    });
  };
  const [submitHandle, setSubmitHandle] = useState(false);
  const close = () => {
    setIsSubmitted(false);
    window.location.reload()
  };
  return (
    <>
      {submitHandle && (
        <div className="transparent--loader">
          <div className="loading">
            <CircularProgress />
          </div>
        </div>
      )}
      <div className="row mb-0s">
        <div className="col-md-12">
          <div className="row">
            <div className="col-2">
              <label
                htmlFor="staticEmail"
                className="col-form-label float-start text-start leftt"
              >
                Case Name
              </label>
            </div>
            <div className="col-10 nme">
              <span className="nme2">{props.caseDetails["caseName"]}</span>
            </div>
            <div className="">
              {/* <button className="float-right pmabtn" disabled={filebase64 ? false : true} onClick={downloadFile}> Download PMA Details</button> */}
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-1s">
        <div className="col-md-4">
          <div className="row">
            <div className="col-4">
              {" "}
              <label
                htmlFor="staticEmail"
                className=" col-form-label float-start text-start leftt"
              >
                NAM ID
              </label>
            </div>
            <div className="col-8 text-left">
              <span className="form-control-plaintext rightt">
                {props.caseDetails["caseReference"]}
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="row">
            <div className="col-4">
              <label
                htmlFor="staticEmail"
                className=" col-form-label float-start text-start leftt"
              >
                Hearing Officer{" "}
              </label>
            </div>
            <div className="col-8 text-left">
              <span
                className="form-control-plaintext rightt"
                style={{ textAlign: "left" }}
              >
                {props.caseDetails["hearingOfficerName"] !== undefined &&
                props.caseDetails["hearingOfficerName"] !== null
                  ? props.caseDetails["hearingOfficerName"]
                  : ""}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-1s">
        <div className="col-md-4">
          <div className="row">
            <div className="col-4">
              <label
                htmlFor="staticEmail"
                className=" col-form-label float-start text-start leftt"
              >
                Case Method
              </label>
            </div>
            <div className="col-8 text-left">
              <span className="form-control-plaintext rightt">
                {props.caseDetails["caseType"]}
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="row">
            {props.caseDetails["caseType"] !== null &&
              props.caseDetails["caseType"] !== undefined &&
              props.caseDetails["caseType"].includes("Arbitration") &&
              props.caseDetails["caseMethod"] !== null &&
              props.caseDetails["caseMethod"] !== undefined &&
              props.caseDetails["caseMethod"] !== "" && (
                <>
                  <div className="col-4">
                    <label
                      htmlFor="staticEmail"
                      className=" col-form-label float-start text-start leftt"
                    >
                      Case Type
                    </label>
                  </div>
                  <div className="col-8 text-left">
                    <span
                      className="form-control-plaintext rightt"
                      style={{ textAlign: "left" }}
                    >
                      {props.caseDetails["caseMethod"] !== undefined &&
                      props.caseDetails["caseMethod"] !== null
                        ? props.caseDetails["caseMethod"]
                        : ""}
                    </span>
                  </div>
                </>
              )}
          </div>
        </div>
      </div>
      <div className="row mb-1s">
        <div className="col-md-4">
          <div className="row" style={{ marginBottom: "1rem" }}>
            <div className="col-4">
              <label
                htmlFor="staticEmail"
                className=" col-form-label float-start text-start leftt"
              >
                Date of conference
              </label>
            </div>
            <div className="col-8 text-left">
              <span className="form-control-plaintext rightt">
                {Utility.getdate(
                  props.caseDetails["hearingStartTimeUTCFromCMS"]
                )}
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-4"></div>
        {
          props?.caseDetails?.caseDemographicsCount>0?<></>: <div className="col-md-4">
          <div className="row" style={{ marginBottom: "1rem" }}>
            <div className="col-4"></div>
            <div className="col-8 text-left">
              <span
                style={{ display: "flex" }}
                className="form-control-plaintext rightt"
              >
                {" "}
                <input
                  type="checkbox"
                  checked={checked}
                  disabled={
                    props.hearingData.demographicExemption
                      .isExemptionRequestRaised === true
                      ? true
                      : false
                  }
                  onChange={onCheckboxChange}
                  style={{
                    cursor: "pointer",
                    width: "20px",
                    height: "20px",
                    marginRight: "4px",
                  }}
                />{" "}
                <p>Request for Exemption</p>
                {props.hearingData.demographicExemption
                  .isExemptionRequestRaised === false &&
                props.hearingData.demographicExemption
                  .isDemographicsExempted === null ? (
                  ""
                ) : (
                  <span
                    style={{
                      backgroundColor:
                        props.hearingData.demographicExemption
                          .isDemographicsExempted === null &&
                        props.hearingData.demographicExemption
                          .isExemptionRequestRaised === true
                          ? "#ffff1a"
                          : props.hearingData.demographicExemption
                              .isDemographicsExempted === true
                          ? "#90EE90"
                          : props.hearingData.demographicExemption
                              .isDemographicsExempted === false
                          ? "red"
                          : "",
                      color: "black",
                      border: "1px solid #ddd",
                      marginLeft: "5px",
                      padding: "2px 6px",
                      borderRadius: "4px",
                      fontSize: "0.9rem",
                      fontWeight: "bold",
                    }}
                  >
                    {props.hearingData.demographicExemption
                      .isDemographicsExempted === null &&
                    props.hearingData.demographicExemption
                      .isExemptionRequestRaised === true
                      ? "Pending"
                      : props.hearingData.demographicExemption
                          .isDemographicsExempted === true
                      ? "Approved"
                      : props.hearingData.demographicExemption
                          .isDemographicsExempted === false
                      ? "Denied"
                      : ""}
                  </span>
                )}
              </span>
            </div>
          </div>
        </div>

        }
       
        {props.caseDetails["isCommercialCase"] &&
          !Utility.IsHearingOfficer() && (
            <div className="col-md-4">
              <div className="row">
                <div className="col-4">
                  <label
                    htmlFor="staticEmail"
                    className=" col-form-label float-start text-start leftt"
                  >
                    Type
                  </label>
                </div>
                <div className="col-8 text-left">
                  <span className="form-control-plaintext rightt">
                    Monthly Billing
                  </span>
                </div>
              </div>
            </div>
          )}
        <div className="col-md-4">
          <div className="row">
            {Utility.getComapreDate(
              Utility.getdate(props.caseDetails["hearingDate"]),
              Utility.getdate(new Date())
            ) && (
              <>
                {" "}
                {props.caseDetails["hearingClosedDate"] !== "" &&
                  props.caseDetails["hearingClosedDate"] !== undefined &&
                  props.caseDetails["hearingClosedDate"] !== null && (
                    <>
                      <div className="col-4">
                        <label
                          htmlFor="staticEmail"
                          className=" col-form-label float-start text-start leftt"
                        >
                          Date Closed
                        </label>
                      </div>
                      <div className="col-8 text-left">
                        <span className="form-control-plaintext rightt">
                          {props.caseDetails["hearingClosedDate"] !==
                            undefined &&
                          props.caseDetails["hearingClosedDate"] !== null
                            ? Utility.getdate(
                                props.caseDetails["hearingClosedDate"]
                              )
                            : "--"}
                        </span>
                      </div>
                    </>
                  )}
              </>
            )}
          </div>
        </div>
      </div>

      <MaterialModal
        open={selectedcasesPopup}
        onClose={() => setselectedcasesPopUp(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropProps={{
          onClick: (e) => e.stopPropagation(),
        }}
      >
        <Box sx={style2} onClick={(e) => e.stopPropagation()}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography></Typography>
            <IconButton
              aria-label="close"
              onClick={() => setselectedcasesPopUp(false)}
              sx={{ marginLeft: "auto" }}
            >
              <CloseIcon sx={{ fontWeight: "bold" }} />
            </IconButton>
          </Box>
          <Box mt={2}>
            <Typography
              variant="h6"
              sx={{ textDecoration: "underline", fontWeight: 600 }}
            >
              Request for Exemption - Demographic
            </Typography>
          </Box>
          <br />
          <Box sx={{ marginTop: "5px" }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item md={2}>
                <Typography>Reason</Typography>
              </Grid>
              <Grid item md={10}>
                <textarea
                  id="w3review"
                  name="w3review"
                  rows="5"
                  style={{
                    width: "100%",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    padding: "8px",
                    boxSizing: "border-box",
                    resize: "vertical",
                  }}
                  value={exemptionReason}
                  maxLength={1000}
                  onChange={handleChangeTextArea}
                />
                <div
                  style={{
                    textAlign: "right",
                    marginTop: "5px",
                    fontSize: "0.875rem",
                  }}
                >
                  {exemptionReason.length}/1000 characters
                </div>
              </Grid>
            </Grid>

            <div style={{ display: "flex", marginTop: "20px" }}>
              <Button
                style={{
                  color: "white",
                  fontWeight: "bold",
                  backgroundColor: "black",
                  border: "2px solid #000",
                  borderRadius: "6px",
                  textTransform: "capitalize",
                  padding: "6px 8px",
                  marginRight: "5px",
                  width: "200px",
                  height: "40px",
                }}
                onClick={handleSave}
              >
                Submit
              </Button>
            </div>
          </Box>
        </Box>
      </MaterialModal>
      {issubmitted && (
        <Modal show={issubmitted} onHide={() => close()}>
          <Modal.Header closeButton>
            <Modal.Title>Demographics</Modal.Title>
          </Modal.Header>
          <Modal.Body>The exemption request has been submitted.</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => close()}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default CaseDataDemographics;
